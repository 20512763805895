<template>
  <div class="article-card-container">
    <template v-if="articles.length">
      <ThePaginator
        v-if="isPaginatorVisible && activePage > 1"
        class="article-card-container__paginator-top"
        :total-pages-count="totalPages"
        :active-page="activePage"
        :page-prefix="'?page='"
        :route-beginning="routeBeginning"
      />
      <div class="container">
        <template v-for="(item, itemIndex) in articlesAndWidgets">
          <!-- только для homepage -->
          <WidgetsRendererContainer
            v-if="isHomePage && item.second.length"
            :key="itemIndex"
            class="article-card-container__widgets home-page-after-tv-widgets"
            :class="item.second[0].type"
            :widgets="item.second"
            widget-class-name="widget-between-posts"
          />
          <component
            :is="calcComponentName(article)"
            v-for="(article, articleIndex) of item.first"
            :key="article.id"
            class="article-card__small-wrapper"
            :class="getArticleCardClass(itemIndex, articleIndex)"
            @appear.once="onAppear(article)"
          >
            <ArticleCardSmall
              class="article-card__small"
              v-bind="useArticleProps(article)"
              :loading-views="getLoadingViews(article.id)"
              :img-loading="getImageLoadingType(itemIndex, articleIndex)"
              @clicked-on-comments="sendEvent"
              @click-on-restore-button="updateViewsCount(article.id)"
              @favorite-delete="(favorite) => onFavoriteDelete(favorite, article)"
              @favorite-create-folder-and-add="
                (nameFolder) => onFavoriteCreateFolderAndAdd(nameFolder, article)
              "
              @favorite-create-folder-and-transfer="
                (nameFolder, favorite) =>
                  onFavoriteCreateFolderAndTransfer(nameFolder, favorite, article)
              "
              @favorite-transfer-to-folder="
                (favorite, folder) => onFavoriteTransferToFolder(favorite, folder, article)
              "
              @favorite-add-to-folder="(folder) => onFavoriteAddToFolder(folder, article)"
              @favorite-deferred="onFavoriteDeferred"
              @click-on-article-card="onClickArticleCardSmall(article, articleIndex + 1)"
              @show-context-menu="onContextMenu(article.id)"
              @clicked-on-author="onClickAuthor"
              @clicked-on-category="onClickCategory"
            />
          </component>
        </template>
      </div>
      <ThePaginator
        v-if="isPaginatorVisible"
        class="article-card-container__paginator-bottom"
        :total-pages-count="totalPages"
        :active-page="activePage"
        :page-prefix="'?page='"
        :route-beginning="routeBeginning"
      />
      <IntersectionObserver
        v-if="isIntersectEnabled"
        ref="observer"
        :options="getIntersectionObserverOptions()"
        @appear="addArticles"
      >
        <ArticleCardSmallPlaceholder v-if="placeholderVisible" />
      </IntersectionObserver>
    </template>
  </div>
</template>

<script lang="ts">
  import { parseData } from '@devhacker/shared/utils/parseData';
  import IntersectionObserver from '~/components/lh-ui/IntersectionObserver/IntersectionObserver.vue';
  import ArticleCard from '~/components/ArticleCard/ArticleCard.vue';
  import ArticleCardSmallPlaceholder from '~/placeholders/ArticleCardSmallPlaceholder/ArticleCardSmallPlaceholder.vue';
  import ThePaginator from '~/components/lh-ui/ThePaginator/ThePaginator.vue';

  import {
    error,
    getActivePage,
    getEditLinkHref,
    getFullUrl,
    isArray,
    isElementInViewport,
  } from '~/utils';
  import { getMixedItemsArray } from '~/utils/arrays';
  import { isHomePage, isArchivePage, isTopPage, isHealthPath, isHomeHealth } from '~/utils/router';
  import { getArticleFetchApi } from '~/utils/apiUtils';
  import { IronMaidenListSchema } from '~/schemas/ironMaiden';

  import { usePageStore } from '~/store/pagesData';
  import { useArticlesStore } from '~/store/articles';
  import { useWidgetsStore } from '~/store/widgets';
  import { useHealthStore } from '~/store/health';
  import { useFoldersStore } from '~/store/folders';
  import { useIndexStore } from '~/store';
  import { useArticleProps } from '~/composables/useArticleProps';
  import { useFavorite } from '@/composables/useFavoriteMethods';
  import { useUpdatingViewsCount } from '@/composables/useUpdatingViewsCount';
  import { hydrateOnVisible } from 'vue';

  const WidgetsRendererContainer = defineAsyncComponent({
    loader: () => import('~/containers/WidgetsRendererContainer.vue'),
    hydrate: hydrateOnVisible(),
  });

  const ArticleCardSmall = defineAsyncComponent({
    loader: () => import('~/components/lh-ui/ArticleCard/ArticleCardSmall'),
    hydrate: hydrateOnVisible(),
  });

  const MAX_PAGES = 4;

  export default defineNuxtComponent({
    name: 'ArticleCardContainer',

    props: {
      showPagination: {
        type: Boolean,
        default: true,
      },
      pageTitle: {
        type: String,
        default: '',
      },
      //! ВАЖНО: тут не индекс, поэтому потом нужно сделать -1
      startFrom: {
        type: Number,
        default: 1,
      },
      allImagesLazy: {
        type: Boolean,
        default: false,
      },
    },

    components: {
      ArticleCardSmallPlaceholder,
      ArticleCard,
      ThePaginator,
      WidgetsRendererContainer,
      ArticleCardSmall,
      IntersectionObserver,
    },

    setup() {
      const {
        onFavoriteDelete,
        onFavoriteCreateFolderAndAdd,
        onFavoriteCreateFolderAndTransfer,
        onFavoriteTransferToFolder,
        onFavoriteAddToFolder,
        onFavoriteDeferred,
      } = useFavorite();
      const { loadingViewsIds, updateViewsCount, getLoadingViews } = useUpdatingViewsCount();

      return {
        onFavoriteDelete,
        onFavoriteCreateFolderAndAdd,
        onFavoriteCreateFolderAndTransfer,
        onFavoriteTransferToFolder,
        onFavoriteAddToFolder,
        onFavoriteDeferred,
        loadingViewsIds,
        updateViewsCount,
        getLoadingViews,
      };
    },

    data() {
      return {
        page: 1 as number,
        loading: false as boolean,

        // Для аналитики. Отслеживание скролла списка материалов. Отправляется через каждые 20 постов.
        // В Label порядковый номер поста: 20, 40, 60, 80, 100, 120
        // Первые 5 карточек в TheTvContainer
        trackedCardNumbers: [15, 35, 55, 75, 95, 115] as number[],

        articlesWithLoadedPixels: [] as number[],
      };
    },

    computed: {
      route: () => useRoute(),
      user: () => useIndexStore().user,
      folders: () => useFoldersStore(),
      isHealthCategory: () => useHealthStore().isHealthCategory,
      pageData: () => usePageStore(),

      articles(): Array<IArticle> {
        const articles = this.pageData.currentArticles;

        if (this.isHealthRoute) {
          return articles;
        }

        const page = getActivePage(this.route);

        // первые 5 статей на главной отображаются в телевизоре
        const startFrom = this.isHomePage ? 5 : page > 1 ? 0 : Math.max(0, this.startFrom - 1); // если с бека приходит 0
        return articles.slice(startFrom);
      },
      totalPages(): number {
        return this.pageData.currentTotalPages;
      },
      favorites(): Array<IFavoriteItem> {
        const articlesStore = useArticlesStore();
        return articlesStore.userRelated.favorites;
      },
      activePage(): number {
        return getActivePage(this.route);
      },

      routeBeginning(): string {
        const { pathMatch, year, month, day } = this.route.params;

        const prefix = this.isHealthRoute ? '/health' : '';

        switch (this.route.name) {
          case 'list-all':
            return `${prefix}/list/`;
          case 'topics-all':
            return `${prefix}/topics/${pathMatch}`;
          case 'tag-all':
            return `${prefix}/tag/${pathMatch}`;
          case 'author-all':
            return `${prefix}/author/${pathMatch}`;
          case 'stream-all':
            return `${prefix}/stream/${pathMatch}`;
          case 'top-all':
            return `${prefix}/top/${pathMatch?.length ? pathMatch : 'week'}`;
          case 'archive-year':
            return `${prefix}/${year}`;
          case 'archive-month':
            return `${prefix}/${year}/${month}`;
          case 'archive-day':
            return `${prefix}/${year}/${month}/${day}`;
          case 'health-article':
            return this.route.path;
        }

        return `${prefix}/list/`;
      },
      isPaginatorVisible(): boolean {
        return this.showPagination && !(this.isHomePage || this.isHealthHome);
      },
      widgetsBetweenPosts(): Array<TWidget> {
        const widgetsStore = useWidgetsStore();
        return widgetsStore.currentBetweenPostsWidgets;
      },
      articlesAndWidgets(): Array<any> {
        // смешанные статьи и виджеты
        return getMixedItemsArray(this.articles, this.widgetsBetweenPosts, 5, 1, false);
      },
      isHealthHome(): boolean {
        const { name } = this.route;
        return isHomeHealth(name);
      },
      isHomePage(): boolean {
        return this.route.name === 'index';
      },
      isTopPage(): boolean {
        return isTopPage(this.route.name);
      },
      isArchivePage(): boolean {
        return isArchivePage(this.route.name);
      },
      isHealthRoute(): boolean {
        return isHealthPath(this.route.path);
      },
      isIntersectEnabled(): boolean {
        return (this.isHomePage || this.isHealthCategory) && this.totalPages > 1;
      },
      loadingNeeded(): boolean {
        return this.pageData.currentArticles.length < 115;
      },
      placeholderVisible(): boolean {
        return this.isHomePage && this.loadingNeeded;
      },
      getArchiveName(): string {
        const { name } = this.route;

        switch (true) {
          case isHomePage(name):
            return 'главная';
          case isArchivePage(name) && !isTopPage(name):
            return 'архивы';
          case isTopPage(name):
            return 'лучшее';
          default:
            return '';
        }
      },
    },

    watch: {
      '$route.fullPath'() {
        this.articlesWithLoadedPixels = [];
      },

      activePage(): void {
        window.scrollTo(0, 0);
      },
    },

    methods: {
      useArticleProps,
      getEditLinkHref,
      isArray,
      sendEvent(slug: string) {
        this.$sendYandexMetrika({
          level1: 'Комментарии_переход к комментариям',
          level4: getFullUrl(slug),
          level6: this.getArchiveName,
        });
      },
      async addArticles(): Promise<void> {
        if (this.isHealthCategory) {
          // для страниц подкатегорий может быть лонгрид со всеми статьями подкатегорий
          this.addArticlesHealthCategory();
          return;
        }

        if (this.page < MAX_PAGES && !this.loading) {
          this.loading = true;
          this.page = this.page + 1;

          const articleApi = getArticleFetchApi(this.route, this);

          const { status, data } = await articleApi.fetchArticles(this.route, this.page);
          this.loading = false;

          if (status === 200) {
            let parsedData = data;

            if (articleApi === this.$ironMaidenApi) {
              parsedData = parseData(data, IronMaidenListSchema);
            }

            const { fullPath, hash } = this.route;
            const path = fullPath.replace(hash, '');
            const { items } = parsedData;
            usePageStore().updateCurrentArticles(path, items);

            // Иногда если пользователь доскроллил страницу до конца,
            // то новые статьи добавляются сверху и не происходит скролл страницы вверх.
            // Поэтому если плейсхолдер сейчас виден на экране или находится выше окна, загружаем следующие статьи.
            const observerEl = (this.$refs.observer as Vue).$el as HTMLElement;
            if (isElementInViewport(observerEl) || observerEl.getBoundingClientRect().top <= 0) {
              this.addArticles();
            }
          } else {
            console.error('Ошибка при автоподгрузке статей');
            error('Ошибка при автоподгрузке статей');
          }
        }
      },
      async addArticlesHealthCategory() {
        // бэк отправляет 0, в том случае если требуется лонгрид, в ином случае ленивая загрузка не требуется
        if (this.startFrom !== 0) {
          return;
        }

        try {
          const currentPageData = this.$store.getters.currentPageData;

          if (this.page < currentPageData.totalPages && !this.loading) {
            const { fullPath, hash } = this.route;
            const { slug } = this.route.params;
            const path = fullPath.replace(hash, '');

            this.loading = true;
            this.page = this.page + 1;

            const archiveCategoryData = await this.$healthApi.fetchCategory(slug, 'posts', {
              page: this.page,
              limit: 30,
            });

            this.loading = false;

            usePageStore().updateCurrentArticles(path, archiveCategoryData.items);
            // Иногда если пользователь доскроллил страницу до конца,
            // то новые статьи добавляются сверху и не происходит скролл страницы вверх.
            // Поэтому если плейсхолдер сейчас виден на экране или находится выше окна, загружаем следующие статьи.
            const observerEl = (this.$refs.observer as Vue).$el as HTMLElement;
            if (isElementInViewport(observerEl) || observerEl.getBoundingClientRect().top <= 0) {
              this.addArticlesHealthCategory();
            }
          }
        } catch (err) {
          console.error('Ошибка при автоподгрузке статей');
          error('Ошибка при автоподгрузке статей');
        }
      },

      getIntersectionObserverOptions(): IntersectionObserverInit {
        if (import.meta.client) {
          return {
            rootMargin: `${window.innerHeight}px 0px ${window.innerHeight}px 0px`,
          };
        } else {
          return {
            rootMargin: '0px 0px 0px 0px',
          };
        }
      },

      onClickArticleCardSmall(article: IArticle, order: number): void {
        this.$sendYandexMetrika({
          level1: 'Выбор материала_Списочная карточка',
          level4: getFullUrl(article.url),
          level5: order,
          level6: this.pageTitle || 'unknown',
          level8: article.teaserUrl ? 'Тизер' : 'Списочная карточка',
        });
      },

      getLabel(path: string) {
        switch (true) {
          case path.includes('month'):
            return 'За месяц';
          case path.includes('year'):
            return 'За год';
          case path.includes('week'):
          default:
            return 'За неделю';
        }
      },

      onClickAuthor(): void {},

      onClickCategory(link: string, titleCategory: string, _title: string): void {
        this.$sendYandexMetrika({
          level1: 'Выбор рубрики',
          level4: getFullUrl(link),
          level5: titleCategory,
          level6: this.pageTitle || 'unknown',
        });
      },

      getTrackingArticleNumber(id: IArticle['id']): number {
        const index = this.articles.findIndex((item) => item.id === id);
        return index + 1;
      },

      isTrackingForAnalyticsNeeded(article: IArticle): boolean {
        const number = this.getTrackingArticleNumber(article.id);
        return this.trackedCardNumbers.includes(number) && isHomePage(this.route.name);
      },

      calcComponentName(article: IArticle): 'div' | 'IntersectionObserver' {
        const isTrackingForAnalyticsNeeded = this.isTrackingForAnalyticsNeeded(article);
        const hasTestPixel = Boolean(article?.testPixel?.length);

        const isTrackingNeeded = hasTestPixel || isTrackingForAnalyticsNeeded;
        return isTrackingNeeded ? 'IntersectionObserver' : 'div';
      },

      onAppear(article: IArticle): void {
        const isTrackingForAnalyticsNeeded = this.isTrackingForAnalyticsNeeded(article);
        if (isTrackingForAnalyticsNeeded) {
          const trackingArticleNumber = this.getTrackingArticleNumber(article.id);
          this.trackedCardNumbers = this.trackedCardNumbers.filter(
            (item) => item !== trackingArticleNumber,
          );
        }

        const testPixel = article?.testPixel;
        const isNotLoadedPixel = !this.articlesWithLoadedPixels.includes(article.id);
        if (testPixel?.length && isNotLoadedPixel) {
          testPixel.forEach((url) =>
            $fetch(url, { credentials: 'include' }).catch((error) => {
              this.$logger(
                'Error in sending text pixels for ' + article + ' in ArticleCardContainer.vue',
                error,
              );
            }),
          );
          this.articlesWithLoadedPixels.push(article.id);
        }
      },

      getArticleCardClass(itemIndex: number, articleIndex: number): string {
        const isFiveElement = articleIndex === 4;
        const nextItemSecond = this.articlesAndWidgets[itemIndex + 1]?.second ?? [];
        // если у следующего элемента articlesAndWidgets есть виджеты
        const nextWidgetExists = nextItemSecond.length > 0;
        return isFiveElement && nextWidgetExists && this.isHomePage
          ? 'article-card__small-wrapper_above-widget'
          : '';
      },

      getTypePage(): string {
        switch (true) {
          case this.isTopPage:
            return 'Лучшее';
          case this.isHomePage:
            return 'Главная';
          case this.isArchivePage:
            return 'Архивы';
          case this.isHealthHome:
            return 'Главная здоровье';
          default:
            return '';
        }
      },

      getImageLoadingType(itemIndex: number, articleIndex: number): string {
        if (this.allImagesLazy) {
          return 'lazy';
        }
        if (itemIndex === 0 && articleIndex < 3) {
          return 'eager';
        }
        if (itemIndex > 1) {
          return 'lazy';
        }
        return 'auto';
      },
      onContextMenu() {
        this.$emit('show-context-menu');
      },
    },
  });
</script>

<style lang="scss" scoped>
  $padding: 16px;
  $paddingTablet: 24px;

  .article-card__small {
    position: relative;

    @include hover {
      z-index: 4;
    }

    // снижает количество элементов для первоначальной отрисовки
    &-wrapper {
      /* content-visibility: auto; */
      contain-intrinsic-width: auto 100vw;
      contain-intrinsic-height: auto 150px;
      // content-visibility cutsoff the border, hack to give it some space
      margin-right: 0.1px;

      @include tablet {
        contain-intrinsic-width: auto 830px;
      }
    }
  }

  .article-card__small-wrapper:not(:last-child):not(.article-card__small-wrapper_above-widget)
    .article-card__small::after {
    position: absolute;
    left: $padding;
    bottom: 0;

    height: 1px;
    width: calc(100% - (#{$padding * 2}));

    background: #e7e7e7;
    content: '';

    @include tablet {
      left: $paddingTablet;

      width: calc(100% - (#{$paddingTablet} * 2));
    }
  }

  .article-card-container__paginator-bottom {
    padding-top: 24px;
    padding-bottom: 40px;

    @include tablet {
      padding-bottom: 48px;
    }
  }

  // убирал верхнюю границу у white-box и lightgrey-box
  // в списке на главной
  // .article-card-container__widgets:not(:first-child) {
  //   margin-top: -1px;
  // }
</style>
